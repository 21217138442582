import React from "react";
import { Card, Button } from "react-bootstrap";
import { getUser } from "../Firebase";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const Home = () => {
  const [user, setUser] = useState("");
  useEffect(() => {
    getUser().then((userResult) => {
      setUser(userResult);
    });
  }, []);
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem("firebaseidtoken");
    navigate("/login");
  };
  if (user.email !== "false")
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card style={{ width: "90vh" }}>
          <Card.Body>
            <h2 className="w-100 text-center mb-4">Profile</h2>
            <div>
              <strong>Email: </strong> {user.email}{" "}
            </div>
            <div>
              <strong>Staff name: </strong> {user.name}
            </div>
            <div>
              <strong>Job: </strong> {user.job}{" "}
            </div>
          </Card.Body>
          {/* <img src={user.picture} /> */}
        </Card>
        <div className="w-100 text-center mt-2">
          <Button variant="outline-info" onClick={logOut}>
            Log Out
          </Button>
          <Button
            variant="outline-info"
            onClick={() => {
              navigate("/post");
            }}
          >
            Post
          </Button>
          <Button
            variant="outline-info"
            onClick={() => {
              navigate("/summary");
            }}
          >
            Summary
          </Button>
        </div>
      </div>
    );
};

export default Home;
