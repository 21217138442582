import React from "react";
import { useState, useEffect } from "react";
import Papa from "papaparse";
import { CsvToHtmlTable } from "react-csv-to-table";
import PostForm from "../component/PostForm";
import { getUser } from "../Firebase";

const config = {
  quotes: false, //or array of booleans
  quoteChar: '"',
  escapeChar: '"',
  delimiter: ",",
  header: true,
  newline: "\r\n",
  skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
  columns: null, //or array of strings}
};

const queryData = async () => {
  const headers = {
    "Content-Type": "application/json",
    Connection: "keep-alive",
    firebaseidtoken: `${localStorage.getItem("firebaseidtoken")}`,
  };
  const res = await fetch("https://ma-sever-ap.onrender.com/product", {
    headers,
  });
  const data = await res.json();
  return data;
};

//React component
const Post = () => {
  //state
  const [displayData, setDisplayData] = useState(null);
  const [staff, setStaff] = useState("");
  //useEffect
  useEffect(() => {
    getUser().then((userResult) => {
      setStaff(userResult);
    });
    queryData().then((dataResult) => {
      setDisplayData(Papa.unparse(dataResult, config));
    });
  }, []);

  //React Component
  return (
    <div>
      {displayData && (
        <CsvToHtmlTable
          data={displayData}
          csvDelimiter=","
          tableClassName="table table-striped table-hover"
        />
      )}
      <PostForm staff={staff.name} />
    </div>
  );
};

export default Post;
