import React from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { FireBaseApp, getUser } from "../Firebase";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import { Button } from "react-bootstrap";

const provider = new GoogleAuthProvider();
const auth = getAuth(FireBaseApp);

const Login = () => {
  const GGAuth = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // The signed-in user info.
        const user = result.user;
        const idToken = await user.getIdToken();
        localStorage.setItem("firebaseidtoken", idToken);
        getUser().then((userResult) => {
          if (userResult.email !== "false") return setUser(true);
          setError("You are not a Stapimex staff");
        });
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        // ...
      });
  };
  const [user, setUser] = useState(false);
  const [error, setError] = useState("");
  if (user) return <Navigate to="/" />;
  return (
    <div>
      {error && <Alert variant="danger">{error}</Alert>}
      <Button onClick={GGAuth}>Login by Google</Button>
    </div>
  );
};

export default Login;
