import React from "react";
import { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { CsvToHtmlTable } from "react-csv-to-table";

const config = {
  quotes: false, //or array of booleans
  quoteChar: '"',
  escapeChar: '"',
  delimiter: ",",
  header: true,
  newline: "\r\n",
  skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
  columns: null, //or array of strings}
};

const downloadExcel = (excelFile) => {
  XLSX.writeFile(excelFile, "DataSheet.xlsx");
};

const queryData = async () => {
  const headers = {
    "Content-Type": "application/json",
    Connection: "keep-alive",
    firebaseidtoken: `${localStorage.getItem("firebaseidtoken")}`,
  };
  const res = await fetch("https://ma-sever-ap.onrender.com/product", {
    headers,
  });
  const data = await res.json();
  return data;
};

const Summary = () => {
  //helper function
  const createExcelFile = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    setExcelFile(workbook);
  };

  //state
  const [excelFile, setExcelFile] = useState(null);
  const [displayData, setDisplayData] = useState(null);

  //useEffect
  useEffect(() => {
    queryData().then((dataResult) => {
      createExcelFile(dataResult);
      setDisplayData(Papa.unparse(dataResult, config));
    });
  }, []);

  //React Component
  return (
    <div>
      {displayData && (
        <CsvToHtmlTable
          data={displayData}
          csvDelimiter=","
          tableClassName="table table-striped table-hover"
        />
      )}
      <button onClick={() => downloadExcel(excelFile)}>
        Download As Excel
      </button>
    </div>
  );
};

export default Summary;
