import { Outlet, Navigate } from "react-router-dom";
import { getUser } from "../Firebase";
import { useState, useEffect } from "react";

const PrivateRoutes = () => {
  const [user, setUser] = useState("");
  useEffect(() => {
    getUser().then((userResult) => {
      setUser(userResult);
    });
  }, []);
  return user.email !== "false" ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
