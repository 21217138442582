// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyAEcva1lQcvJbZycPpOOiFz8zgPYLWH3kg",
  authDomain: "stapimex-biology-test.firebaseapp.com",
  databaseURL:
    "https://stapimex-biology-test-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "stapimex-biology-test",
  storageBucket: "stapimex-biology-test.appspot.com",
  messagingSenderId: "39528248664",
  appId: "1:39528248664:web:8d47e668c976af41e0612d",
  measurementId: "G-H5X29MTCCR",
};

// Initialize Firebase
export const FireBaseApp = initializeApp(firebaseConfig);

export const getUser = async () => {
  const firebaseidtoken = localStorage.getItem("firebaseidtoken");
  if (firebaseidtoken == null) return { email: "false" };
  const headers = {
    "Content-Type": "application/json",
    Connection: "keep-alive",
    firebaseidtoken: `${firebaseidtoken}`,
  };
  const res = await fetch("https://ma-sever-ap.onrender.com/getUser", {
    headers,
  });
  const user = await res.json();
  return user;
};
