import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Summary from "./pages/Summary";
import Post from "./pages/Post";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route element={<Home />} path="/" exact></Route>
            <Route element={<Summary />} path="/summary" exact></Route>
            <Route element={<Post />} path="/post"></Route>
          </Route>
          <Route element={<Login />} path="/login" exact />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
