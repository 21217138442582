import React from "react";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const PostForm = (props) => {
  const [validated, setValidated] = useState(false);
  const [maNhanHang, setMaNhanHang] = useState("");
  const [congTy, setCongTy] = useState("");
  const [matHang, setMatHang] = useState("");
  const [khoiLuong, setKhoiLuong] = useState("");
  const [ngayNhan, setNgayNhan] = useState(null);
  const [maXetNghiem, setMaXetNghiem] = useState("");
  const [justUpload, setJustUpload] = useState(false);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      uploadData();
      setJustUpload(true);
    }
    setValidated(true);
  };
  const uploadData = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Ma_nhan_hang: `${maNhanHang}`,
        Cong_ty: `${congTy}`,
        Mat_hang: `${matHang}`,
        Khoi_luong: `${khoiLuong}`,
        Ngay_nhan: `${ngayNhan}`,
        Ma_xet_nghiem: `${maXetNghiem}`,
        Nguoi_nhap_lieu: `${props.staff}`,
      }),
    };
    fetch(`https://ma-sever-ap.onrender.com/product`, requestOptions).then(
      (response) => {
        response.json();
        console.log("done");
      }
    );
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      onChange={() => {}}
    >
      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>Ma nhan hang</Form.Label>
          <Form.Control
            required
            type="text"
            onChange={(e) => setMaNhanHang(e.target.value)}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Cong ty</Form.Label>
          <Form.Control
            required
            type="text"
            onChange={(e) => setCongTy(e.target.value)}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustomUsername">
          <Form.Label>Mat hang</Form.Label>
          <Form.Control
            type="text"
            aria-describedby="inputGroupPrepend"
            required
            onChange={(e) => setMatHang(e.target.value)}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom03">
          <Form.Label>Khoi luong</Form.Label>
          <Form.Control
            type="text"
            required
            onChange={(e) => setKhoiLuong(e.target.value)}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationCustom04">
          <Form.Label>Ngay nhan</Form.Label>
          <Form.Control
            type="date"
            required
            onChange={(e) => setNgayNhan(e.target.value)}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationCustom05">
          <Form.Label>Ma xet nghiem</Form.Label>
          <Form.Control
            type="text"
            required
            onChange={(e) => setMaXetNghiem(e.target.value)}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3">
        <Form.Check
          required
          label="Da kiem tra ki du lieu sap nhap!"
          feedback="Ban phai kiem tra du lieu truoc khi nhap!."
          feedbackType="invalid"
        />
      </Form.Group>
      <Button type="submit">Upload du lieu</Button>
      {justUpload && <Alert variant="success">Done uploading</Alert>}
    </Form>
  );
};

export default PostForm;
